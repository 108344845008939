import { Box } from "@mui/material";
import styled from "styled-components";
import { MinTablet600 } from "../../responsive";

export const MenuBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
 
  ${MinTablet600`
    align-items: flex-end;
  `};
 
`

export const ItemBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${MinTablet600`
    gap: 50px;
  `};
  

`

// sx={{
//   width: '100%',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-end',
//   p: 1,
// }}
