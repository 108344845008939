import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../reducers/authSlice';
import { selectCartTotalQuantity } from '../../reducers/cartSlice';
import { Box, Typography, IconButton, Avatar, Badge, Tooltip } from '@mui/material';
import { CartIcon,  Link } from '../../globalStyle';
import { ButtonMui } from '../button/button.mui';
import ConfirmDialog from '../confirm-dialog/confirm-dialog.component';
import { useNavigate } from 'react-router-dom';
import CustomMenu from './custom-menu';
import { ItemBox, MenuBox } from './account-menu.style';

const AccountMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalQuantity = useSelector(selectCartTotalQuantity);
  const userAuth = useSelector((state) => state.auth.user);
  const formattedName = useMemo(() => userAuth?.name?.toUpperCase(), [userAuth]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmLogout = () => {
    dispatch(logout());
    handleClose();
  };

  const handleNavigate = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    
      <MenuBox>
        <ItemBox>
          <Typography sx={{padding: 1}}>
            <Badge badgeContent={totalQuantity} color="warning">
              <Link to="/cart">
                <CartIcon color="primary" />
              </Link>
            </Badge>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>{formattedName}</Typography>
            {!userAuth ? (
              <Link to="/user/auth" color="primary">
                <ButtonMui variant="outlined">Login | Register</ButtonMui>
              </Link>
            ) : (
              <Tooltip title="Account settings">
                <IconButton onClick={handleClick} size="small">
                  <Avatar sx={{ width: 32, height: 32 }}>{formattedName?.charAt(0)}</Avatar>
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* Custom Account Menu */}
          {userAuth && (
            <CustomMenu
              open={menuOpen}
              onClose={handleClose}
              onNavigate={handleNavigate}
              onLogout={handleLogout}
            />
          )}
        </ItemBox>


        {/* Confirm Logout Dialog */}
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleConfirmLogout}
          title="Confirm Logout"
          message="Are you sure you want to log out? You will be redirected to the login page."
        />
      </MenuBox>
    
  );
};

export default AccountMenu;
