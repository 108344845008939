import React, { useState, useEffect } from 'react';
import { Modal, Fade, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SubscriberForm from '../subscriber-form/subscriber-form.component';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  // padding: theme.spacing(2, 4, 3),
  borderRadius: theme.shape.borderRadius,
  outline: 'none',
  maxWidth: '90%',
  maxHeight: '90%',
  // overflow: 'auto',
}));



export default function DelayedSubscriberModal({ delay = 7000 }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="delayed-subscriber-modal"
      aria-describedby="delayed-subscriber-form"
    >
      <Fade in={open}>
        <StyledModalContent>
          <SubscriberForm />
        </StyledModalContent>
      </Fade>
    </StyledModal>
  );
}