import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MinTablet600 } from "../../responsive";
import { Container } from "../../globalStyle";
import { Box, IconButton, Skeleton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { capitalizeFirstLetter } from '../../helpers/globalHelper';

const BottomHeaderSection = styled.nav`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem 0;
  position: relative;
  width: 100%;
`;

const HeaderContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  width: 100%;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 3px;
  }

  ${MinTablet600({
    flexDirection: 'row',
    overflowX: 'auto',
    overflowY: 'hidden',
    maxHeight: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  })}
`;

const CategoryButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  white-space: nowrap;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
  text-align: left;
  border-radius: 4px;

  &:hover, &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;
  }

  ${MinTablet600({
    width: 'auto',
    textAlign: 'center',
    padding: '0.5rem 1rem',
  })}
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.25rem 0;

  ${MinTablet600({
    width: 'auto',
    margin: '0 0.5rem'
  })}
`;

const ScrollButtons = styled.div`
  display: none;

  ${MinTablet600({
    display: 'block'
  })}
`;

const Navbar = () => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { categories, status } = useSelector((state) => state.categories);
  const location = useLocation();

  const checkScrollButtons = useCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  }, []);

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);
    return () => window.removeEventListener("resize", checkScrollButtons);
  }, [checkScrollButtons]);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 200;
      scrollRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const renderContent = () => {
    if (status === "loading") {
      return (
        <>
          {[...Array(10)].map((_, index) => (
            <CategoryItem key={index}>
              <Skeleton variant="rectangular" width="100%" height={44} style={{ borderRadius: '4px' }} />
            </CategoryItem>
          ))}
        </>
      );
    }

    if (status === "failed") {
      return <Box sx={{ color: 'error.main', width: '100%', textAlign: 'center', padding: '1rem' }}>Error loading categories</Box>;
    }

    return (
      <>
        <CategoryItem>
          <Link to="/" style={{ textDecoration: 'none', width: '100%' }}>
            <CategoryButton className={location.pathname === '/' ? 'active' : ''}>
              Home
            </CategoryButton>
          </Link>
        </CategoryItem>
        {categories.map((category) => (
          <CategoryItem key={category.id}>
            <Link
              to={`/shop/${category.category_slug}/${category.id}`}
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <CategoryButton
                className={location.pathname === `/shop/${category.category_slug}/${category.id}` ? 'active' : ''}
              >
                {capitalizeFirstLetter(category.name)}
              </CategoryButton>
            </Link>
          </CategoryItem>
        ))}
      </>
    );
  };

  return (
    <BottomHeaderSection>
      <HeaderContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
          <ScrollButtons>
            {showLeftArrow && (
              <IconButton
                onClick={() => scroll("left")}
                sx={{
                  position: 'absolute',
                  left: 0,
                  zIndex: 2,
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'background.paper' },
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
          </ScrollButtons>
          <ScrollContainer ref={scrollRef} onScroll={checkScrollButtons}>
            {renderContent()}
          </ScrollContainer>
          <ScrollButtons>
            {showRightArrow && (
              <IconButton
                onClick={() => scroll("right")}
                sx={{
                  position: 'absolute',
                  right: 0,
                  zIndex: 2,
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'background.paper' },
                }}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </ScrollButtons>
        </Box>
      </HeaderContainer>
    </BottomHeaderSection>
  );
};

export default Navbar;