import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppContainer = styled.div`
  position: fixed;
  right: 20px;
  z-index: 20;
  transition: bottom 0.3s ease-in-out;
  bottom: ${props => props.$isScrolled ? '130px' : '20px'};

  @media (min-width: 769px) {
    bottom: ${props => props.$isScrolled ? '90px' : '20px'};
  }
`;

const WhatsAppIconWrapper = styled.a`
  background-color: #25D366;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;

  &:hover {
    background: #fff;
    color: #25D366;
    border: 2px solid #25D366;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    height: 36px;
    width: 36px;
    font-size: 16px;
  }
`;

const WhatsAppButtonSupport = ({ phoneNumber = "923187637515" }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <WhatsAppContainer $isScrolled={isScrolled}>
      <WhatsAppIconWrapper 
        href={`https://api.whatsapp.com/send/?phone=${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <WhatsAppIcon fontSize="small" />
      </WhatsAppIconWrapper>
    </WhatsAppContainer>
  );
};

export default WhatsAppButtonSupport;