import { Box, Grid, IconButton } from "@mui/material"
import { Container, Link, MenuIcon, } from "../../globalStyle"
import Navbar from "../navbar/navbar.component"
import { AccountLinks, HeaderSection, MainContainer } from "./header.style"
import HeaderMobile from "../header-mobile/header-mobile.component";
import { useGlobalState } from "../../context/GlobalStates";
import "../../responsive.css";
import AccountMenu from "../account-menu/account-menu.component";
import Announcement from "../announcement/announcement.component";
import SearchQueryForm from "../search-query-form/search-query-form.component";
import { useSelector } from "react-redux";
import NewYouLogo from "../newyou-logo/newyou-logo.component";

const Header = () => {
  const { categories } = useSelector((state) => state.categories);
  const { isMenuOpen, setIsMenuOpen } = useGlobalState();
  return (
    <HeaderSection>
      <MainContainer>
        <Announcement />
        <Container>
          <Grid container spacing={0}
            sx={{ height: '100px', display: 'flex', alignItems: 'center' }}
          >
            <Grid item xs={12} sm={3}>
              <Box className="Desktop">
                <Link to="/">
                  <NewYouLogo newyou width="200" height="100" />
                </Link>
              </Box>
              <Box
                className="Mobile"
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Link to="/">
                  <NewYouLogo device="Mobile" newyou width="100" height="50" />
                </Link>
                <div className='Mobile'>
                  <IconButton onClick={() => setIsMenuOpen((prev) => !prev)}>
                    <MenuIcon fontSize='large' />
                  </IconButton>
                </div>
              </Box>

            </Grid>
            <Grid item xs={12} sm={6} marginTop={2} marginBottom={2}>
              <SearchQueryForm />
            </Grid>
            <Grid item xs={12} sm={3}>
              <AccountLinks>
                <AccountMenu />
              </AccountLinks>
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
      <div className="Desktop">
        <Navbar
          categories={categories}
        />
      </div>
      {isMenuOpen && <HeaderMobile />}
    </HeaderSection>
  )
}

export default Header;