import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import {
  initGA,
  logPageView
} from '../component/google-analytics/google-analytics';

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

}

export default useGoogleAnalytics