class AppURL {
    // static HomeURL = "http://127.0.0.1:8000/api/";
    static HomeURL = "https://admin.newyousports.shop/api/";

    static VisitorDetails() {
        return this.HomeURL + "visitor-info";
    }
    static ApiSubmitQuote() {
        return this.HomeURL + "quotations";
    }


    static ApiLogin() {
        return this.HomeURL + "login";
    }
    static ApiUser() {
        return this.HomeURL + "user";
    }
    static ApiUserRegister() {
        return this.HomeURL + "register";
    }
    static ApiEmailVerification() {
        return this.HomeURL + "email/verification-notification";
    }
    static ApiEmailIsVerified(id, hash) {
        return this.HomeURL + "verify-email/verify-email/" + id + "/" + hash;
    }

    static ResetPassword() {
        return this.HomeURL + "password/reset";
    }

    static ForgotPassword() {
        return this.HomeURL + "password/forgot";
    }
    static UpdatePassword() {
        return this.HomeURL + "update-password";
    }
    static fetchUserDetail() {
        return this.HomeURL + "user";
    }
    static updateUserDetail() {
        return this.HomeURL + "user/name";
    }
    static deleteUser() {
        return this.HomeURL + "user";
    }
    static ApiUserFetchMockups() {
        return this.HomeURL + "mockups";
    }
    static ApiFetchMockupForCart(mockupId) {
        return this.HomeURL + "mockups/for-cart/" + mockupId;
    }
    static ApiGetSizes() {
        return this.HomeURL + "get/sizes"
    }
    static ApiFetchMockupById(id) {
        return this.HomeURL + "mockups/" + id
    }

    static ApiFetchShopProducts() {
        return this.HomeURL + "products/shop";
    }
    static ApiFetchCategoreis() {
        return this.HomeURL + "categories"
    }
    static ApiGetGroupsById(productId) {
        return this.HomeURL + "groups/by-product/" + productId
    }
    static ApiGetProductsForCart(productId) {
        return this.HomeURL + "products/for-cart/" + productId;
    }

    static ApiGetProductById(productId) {
        return this.HomeURL + "products/by-id/" + productId;
    }

    static ApiGetFeaturedProducts() {
        return this.HomeURL + "get/product/featured";
    }

    static ApiPromotionProducts() {
        return this.HomeURL + "promotion";
    }

    static ApiAddresses() {
        return this.HomeURL + "addresses";
    }
    static ApiGetShipAddress() {
        return this.HomeURL + "addresses/getship";
    }
    static ApiUpdateShipTo($addressId) {
        return this.HomeURL + "addresses/" + $addressId + "/ship-to";
    }
    static ApiSetDefaultAddress($addressId) {
        return this.HomeURL + "addresses/" + $addressId + "/set-default";
    }
    static ApiUpdateAddress($addressId) {
        return this.HomeURL + "addresses/" + $addressId + "/edit";
    }
    static ApiDeleteAddress($addressId) {
        return this.HomeURL + "addresses/" + $addressId + "/delete";
    }
    static ApiTeams() {
        return this.HomeURL + "teams";
    }
    static ApiTeamsById(teamId) {
        return this.HomeURL + "teams/" + teamId;
    }
    static ApiUpdateTeam(teamId) {
        return this.HomeURL + "teams/" + teamId + "/update";
    }
    static ApiDeleteTeam = (teamId) => {
        return this.HomeURL + "teams/" + teamId + "/delete";
    }
    static ApiTeamsSelected() {
        return this.HomeURL + "teams/selected";
    }
    static ApiUpdateTeamsSelected() {
        return this.HomeURL + "teams/update/selected";
    }
    static ApiAddTeamsPlayer() {
        return this.HomeURL + "teams/add/player";
    }
    static ApiUpdateTeamsPlayer() {
        return this.HomeURL + "teams/update/player";
    }
    static ApiDeleteTeamsPlayer(teamId, playerId) {
        return this.HomeURL + "teams/" + teamId + "/delete/player/" + playerId;
    }

    static ApiFetchWallet = (userId) => {
        return this.HomeURL + "wallet/" + userId;
    }
    static ApiValidateCouponCode = (code) => {
        return this.HomeURL + "coupons/validate/" + code;
    }
    static ApiCreateOrder = () => {
        return this.HomeURL + "order/create";
    }
    static ApiGetAllOrder = () => {
        return this.HomeURL + "order/get/all";
    }
    static ApiGetOrderStages = () => {
        return this.HomeURL + "order/get/stages";

    }

    static ApiSubmiteReview() {
        return this.HomeURL + "review";
    }
    static ApiGetProductReviews($productId) {
        return this.HomeURL + "review/product/" + $productId;
    }

    static ApiSubmitReviewVote($reviewId) {
        return this.HomeURL + "review/" + $reviewId + "/vote";
    }

    static ApiGetFeedbacks() {
        return this.HomeURL + "get/feedbacks";
    }

    static ApiGetGeneralQuestions() {
        return this.HomeURL + "questions/general";
    }
    static ApiGetProductQuestions(productId) {
        return this.HomeURL + "products/" + productId + "/questions";
    }
    static ApiPostQuestion() {
        return this.HomeURL + "ask/question";
    }
    static ApiGetBanners() {
        return this.HomeURL + "banners";
    }
    static ApiSearchQuery() {
        return this.HomeURL + "search";
    }
    static ApiUserBanners() {
        return this.HomeURL + "user/banners";
    }
    static ApiBannerLogs() {
        return this.HomeURL + "banner/logs";
    }
    static ApiRecentViewTrack() {
        return this.HomeURL + "recent-views/track";
    }
    static ApiPopularViewTrack() {
        return this.HomeURL + "popular-views/track";
    }
    static ApiGetRecentViewProducts() {
        return this.HomeURL + "get/product/recent";
    }

    static ApiGetPopularProducts() {
        return this.HomeURL + "get/product/popular";
    }
    static ApiGetNewArrivalsProduct() {
        return this.HomeURL + "get/product/new-arrivals";
    }
    static ApiRelatedProducts() {
        return this.HomeURL + "related/products";
    }
    static ApiGetSlider() {
        return this.HomeURL + "sliders";
    }
    static ApiSubscribes() {
        return this.HomeURL + "subscribe";
    }




}


export default AppURL;

