import React from 'react'
import {
  CardItemBox,
  CardItemDesc,
  CardItemPrice,
  CardItemTitle,
  ImageContainer,
  ProductHeading,
  MinOrderText,
  RatingWrapper
} from './product-widget-card-item.style'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import ProductRating from '../product-rating-component/product-rating.component'
import FormatPrice from '../../helpers/formatPrice'
import { capitalizeFirstLetter } from '../../helpers/globalHelper'
import { Link } from '../../globalStyle'
import { calculateAverageRating } from '../../helpers/reviewHelper'
import ImagePlaceholder from '../image-placeholder/image-placeholder.component'

const ProductWidgetCardItem = ({ product = '', niceName = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const averageRating = calculateAverageRating(product);

  return (
    <Box sx={{ margin: '10px 0' }}>
      {product.nice_name && niceName && (
        <ProductHeading>
          {capitalizeFirstLetter(product.nice_name)}
        </ProductHeading>
      )}
      <CardItemBox>
        <Link to={`/single/product/${product?.slug}/${product?.id}`}>
          <ImageContainer>
            <ImagePlaceholder
              src={product?.thumbnail}
              alt={product?.name}
              width={isMobile ? "100px" : "150px"}
              height={isMobile ? "75px" : "100px"}
              className="widget--image"
              object="contain"
            />
          </ImageContainer>
        </Link>
        <CardItemDesc>
          <Link to={`/single/product/${product?.slug}/${product?.id}`}>
            <CardItemTitle variant={isMobile ? "subtitle1" : "h6"}>
              {product?.name.length > (isMobile ? 40 : 50)
                ? capitalizeFirstLetter(product?.name.slice(0, isMobile ? 40 : 50)) + '...'
                : capitalizeFirstLetter(product?.name)}
            </CardItemTitle>
          </Link>
          <MinOrderText variant={isMobile ? 'caption' : 'body2'}>
            Min.Order: {product?.min_qty}
          </MinOrderText>
          <RatingWrapper>
            <ProductRating value={averageRating} />
          </RatingWrapper>
          <CardItemPrice>
            <FormatPrice price={product?.price} />
          </CardItemPrice>
        </CardItemDesc>
      </CardItemBox>
    </Box>
  )
}

export default ProductWidgetCardItem