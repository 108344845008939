import { Box } from "@mui/material";
import styled from "styled-components";

// Container that wraps the scroll-to-top button
export const ScrollTopContainer = styled(Box)`
  position: fixed;
  bottom: 80px; // Increased to avoid overlap with bottom navigation
  right: 20px;
  z-index: 20;

  @media (min-width: 769px) {
    bottom: 40px; // Adjust for desktop where there's no bottom navigation
  }
`;

// Styled icon container
export const ScrollIcon = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid #fff;
  border-radius: 50%;
  height: 40px; // Reduced size
  width: 40px; // Reduced size
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; // Slightly reduced font size
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Added subtle shadow for depth

  &:hover {
    background: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    transform: scale(1.05); // Slight scale effect on hover
  }

  @media (max-width: 768px) {
    height: 36px; // Even smaller on mobile
    width: 36px; // Even smaller on mobile
    font-size: 16px; // Smaller font size on mobile
  }
`;