import { Diversity1, Logout, ManageAccounts, Redeem, Settings, ViewList } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  position: absolute;
  top: 50px; /* Adjust this value to align below the Avatar */
  right: 0; /* Aligns the menu to the right */
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px; /* Adjust as needed */
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const MenuItem = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  &:hover {
    background: #f0f0f0; /* Hover effect */
  }
`;

const Divider = styled.div`
  height: 1px;
  background: #e0e0e0;
  margin: 5px 0;
`;

const CustomMenu = ({ open, onClose, onNavigate, onLogout }) => {
  if (!open) return null;

  return (
    <MenuContainer open={open}>
      <MenuItem onClick={() => { onNavigate('/user/account-details'); onClose(); }}>
        <ManageAccounts />
        <Typography variant="span">Profile</Typography>
      </MenuItem>
      <MenuItem onClick={() => { onNavigate('/user/orders'); onClose(); }}>
        <ViewList />
        <Typography variant="span">My Orders</Typography>
      </MenuItem>
      <MenuItem onClick={() => { onNavigate('/user/my-teams'); onClose(); }}>
        <Diversity1 />
        <Typography variant="span"> My Teams</Typography>
      </MenuItem>
      <MenuItem onClick={() => { onNavigate('/user/mockups'); onClose(); }}>
        <Redeem />
        <Typography variant="span"> My Mockups</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => { onNavigate('/user/dashboard'); onClose(); }}>
        <Settings />
        <Typography variant="span"> Dashboard</Typography>
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <Logout />
        <Typography variant="span"> Logout</Typography>
      </MenuItem>
    </MenuContainer>
  );
};

export default CustomMenu;
