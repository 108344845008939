import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { Mobile } from "../../responsive";

export const CardItemBox = styled(Box)`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: flex-start;
`;

export const CardItemDesc = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
`;

export const ImageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightlowgray};
  border-radius: 8px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  
  ${Mobile({
    width: '100px',
    height: '75px',
  })}
`;

export const Image = styled.img`
  &.widget--image {
    object-fit: contain;
    max-width: 97%;
    max-height: 95%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export const CardItemTitle = styled(Typography)`
  font-weight: 700;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 0.5px;
  margin-bottom: 2px;

  ${Mobile({
    fontSize: '0.95rem',
    lineHeight: '1.1',
  })}
`;

export const MinOrderText = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray};
  
  ${Mobile({
    fontSize: '0.75rem',
  })}
`;

export const RatingWrapper = styled.div`
  margin: 2px 0;
`;

export const CardItemPrice = styled(Box)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  margin-top: 4px;

  ${Mobile({
    fontSize: '0.9rem',
  })}
`;

export const ProductHeading = styled.h3`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 0.6rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;

  &::after {
    content: "";
    width: 80px;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ${Mobile({
    fontSize: "1rem",
    paddingBottom: "0.4rem",
    marginBottom: "0.8rem",
  })}
`;