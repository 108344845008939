// googleAnalytics.js
import ReactGA from 'react-ga4';

export const googleTrackingId = 'G-BN57SNT9JQ'; // Your GA4 tracking ID

let isInitialized = false;

export const initGA = () => {
  if (!isInitialized) {
    ReactGA.initialize(googleTrackingId);
    isInitialized = true;
  }
};

export const logPageView = () => {
  const pagePath = window.location.pathname + window.location.search;
  ReactGA.send({ hitType: 'pageview', page: pagePath });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
}

export const logException = (description, fatal = false) => {
  ReactGA.exception({ description, fatal });
}
