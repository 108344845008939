import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AppURL from '../api/AppURL';
import axios from 'axios';

export const fetchPromotionProducts = createAsyncThunk(
  'promotions/fetchPromotionProducts',
  async ({ type, signal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(AppURL.ApiPromotionProducts(), {
        params: { type: type },
        signal, // Attach the signal for aborting the request if needed
      });

      // Axios treats any response outside the range of 2xx as an error by default
      // So no need to check `response.ok`
      return response.data; // Axios automatically parses the response as JSON
    } catch (error) {
      // Check if the error is due to the request being aborted
      if (axios.isCancel(error)) {
        return rejectWithValue('Request was canceled');
      }

      // Return a more detailed error message, or fallback to the message
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Failed to fetch promotion products'
      );
    }
  }
);

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotionProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPromotionProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.data; // Adjust based on your API response structure
      })
      .addCase(fetchPromotionProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default promotionsSlice.reducer;
