import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import AppURL from "../api/AppURL";

// Thunk for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post(AppURL.ApiLogin(), { email, password });
      const token = response.data.token;
      const tokenExpiry = response.data.token_expiry;

      // Fetch the user data using the token
      const userData = await thunkAPI.dispatch(fetchUser(token));

      // Check if fetchUser returns a fulfilled action with payload
      if (fetchUser.fulfilled.match(userData)) {
        return { token, tokenExpiry, user: userData.payload };
      } else {
        return thunkAPI.rejectWithValue({ error: 'Failed to fetch user data after login' });
      }

    } catch (error) {
      const message = error.response?.data?.message || 'An error occurred during login';
      return thunkAPI.rejectWithValue({ error: message });
    }
  }
);

// Thunk for registration
export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ name, email, password }, thunkAPI) => {
    try {
      const response = await axios.post(AppURL.ApiUserRegister(), { name, email, password });
      const token = response.data.token;
      const tokenExpiry = response.data.token_expiry;

      // Fetch the user data using the token
      const userData = await thunkAPI.dispatch(fetchUser(token));

      // Check if fetchUser returns a fulfilled action with payload
      if (fetchUser.fulfilled.match(userData)) {
        return { token, tokenExpiry, user: userData.payload };
      } else {
        return thunkAPI.rejectWithValue({ error: 'Failed to fetch user data after registration' });
      }

    } catch (error) {
      let message = 'An error occurred during registration';

      // Check for validation errors (422 status)
      if (error.response && error.response.status === 422) {
        // Extract and format validation errors
        const validationErrors = error.response.data;
        message = Object.values(validationErrors).flat().join(', ');
      } else {
        // Other error messages from the server
        message = error.response?.data?.message || message;
      }

      return thunkAPI.rejectWithValue({ error: message });
    }
  }
);

// Thunk for fetching user data
export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (token, thunkAPI) => {
    try {
      const user = await axios.get(AppURL.ApiUser(), {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      return user.data;
    } catch (error) {
      const message = error.response?.data?.message || 'An error occurred while fetching user data';
      return thunkAPI.rejectWithValue({ error: message });
    }
  }
);

// Initial state
const initialState = {
  user: null,
  token: null,
  tokenExpiry: null,
  status: 'idle',
  error: null,
  loading: false,
};

// Create the auth slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.tokenExpiry = null;
      state.status = 'idle';
      state.error = null;
    },
    updateEmailVerifiedStatus(state, action) {
      if (state.user) {
        state.user.email_verified_at = action.payload; // Update the verified status
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.tokenExpiry = action.payload.tokenExpiry;
        state.user = action.payload.user;
        state.status = 'succeeded';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || 'Login failed';
        state.status = 'failed';
      })

      // Registration
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        state.tokenExpiry = action.payload.tokenExpiry;
        state.user = action.payload.user;
        state.status = 'succeeded';
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || 'Registration failed';
        state.status = 'failed';
      })

      // Fetch user data
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || 'Failed to fetch user data';
        state.status = 'failed';
      });
  },
});

export const { logout, updateEmailVerifiedStatus } = authSlice.actions;
export default authSlice.reducer;
