import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from "../../globalStyle";
import { capitalizeFirstLetter } from '../../helpers/globalHelper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import { selectCartTotalQuantity } from '../../reducers/cartSlice';
import { Badge } from '@mui/material';

const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border};
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateY(${({ $show }) => ($show ? '0' : '100%')});
  z-index: 1000;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavContent = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const CategoryScroll = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  flex: 1;
  margin: 0 1rem;
`;

const CategoryButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  white-space: nowrap;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`;

const IconButton = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  text-decoration: none;
`;

const MobileBottomNav = () => {
  const totalQuantity = useSelector(selectCartTotalQuantity);
  const [show, setShow] = useState(false);
  const { categories } = useSelector((state) => state.categories);
  const location = useLocation();
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY.current) {
        setShow(true);
      } else if (currentScrollY < prevScrollY.current) {
        setShow(false);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <NavContainer $show={show}>
      <NavContent>
        <Badge badgeContent={totalQuantity} color="warning">
          <IconButton to="/cart">
            <ShoppingCartIcon />
          </IconButton>
        </Badge>
        <CategoryScroll>
          <CategoryButton
            as={Link}
            to="/"
            className={location.pathname === '/' ? 'active' : ''}
          >
            Home
          </CategoryButton>
          {categories.map((category) => (
            <CategoryButton
              key={category.id}
              as={Link}
              to={`/shop/${category.category_slug}/${category.id}`}
              className={location.pathname === `/shop/${category.category_slug}/${category.id}` ? 'active' : ''}
            >
              {capitalizeFirstLetter(category.name)}
            </CategoryButton>
          ))}
        </CategoryScroll>
        <IconButton to="/user/auth">
          <PersonIcon />
        </IconButton>
      </NavContent>
    </NavContainer>
  );
};

export default MobileBottomNav;