import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import customizationReducer from './reducers/customizationReducer';
import quotationReducer from "./reducers/quotationReducer";
import productsReducer from "./reducers/productsReducer";
import authReducer from "./reducers/authSlice";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import cartReducer from "./reducers/cartSlice";
import addressReducer from "./reducers/addressSlice";
import teamReducer from "./reducers/teamSlice";
import axiosAuthMiddleware from "./middleware/axiosAuthMiddleware";
import checkoutReducer from "./reducers/checkoutSlice";
import walletReducer from "./reducers/walletSlice";
import couponReducer from "./reducers/couponSlice";
import orderReducer from "./reducers/orderSlice";
import mockupReducer from "./reducers/mockupSlice";
import sizesReducer from "./reducers/sizesSlice";
import groupReducer from "./reducers/groupSlice";
import reviewReducer from "./reducers/reviewSlice";
import feedbackReducer from "./reducers/feedbackSlice";
import questionReducer from "./reducers/questionSlice";
import bannerReducer from "./reducers/bannerSlice";
import recentViewReducer from "./reducers/recentViewSlice";
import popularViewReducer from "./reducers/popularViewSlice";
import newArrivalsReducer from "./reducers/newArrivalsSlice";
import mockupBannerReducer from "./reducers/mockupBannerSlice";
import relatedProductsReducer from "./reducers/relatedProductsSlice";
import categoriesReducer from "./reducers/categoriesSlice";
import shopReducer from "./reducers/shopSlice";
import searchReducer from "./reducers/searchSlice";
import sliderReducer from "./reducers/sliderSlice";
import featuredProductReducer from "./reducers/featuredProductSlice";
import subscriberReducer from "./reducers/subscriberSlice";
import snackbarReducer from "./reducers/snackbarSlice";
import userReducer from "./reducers/userSlice";
import visitorReducer from "./reducers/visitorSlice";
import emailVerificationReducer from "./reducers/emailVerificationSlice";
import promotionsReducer from "./reducers/promotionsSlice";





// Persist config
const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['auth', 'cart'],
};

const rootReducer = combineReducers({
  customization: customizationReducer,
  quotation: quotationReducer,
  products: productsReducer,
  auth: authReducer,
  cart: cartReducer,
  addresses: addressReducer,
  teams: teamReducer,
  checkout: checkoutReducer,
  wallet: walletReducer,
  coupon: couponReducer,
  order: orderReducer,
  mockups: mockupReducer,
  sizes: sizesReducer,
  groups: groupReducer,
  reviews: reviewReducer,
  feedbacks: feedbackReducer,
  questions: questionReducer,
  banners: bannerReducer,
  recentViews: recentViewReducer,
  popularViews: popularViewReducer,
  newArrivals: newArrivalsReducer,
  mockupBanners: mockupBannerReducer,
  relatedProducts: relatedProductsReducer,
  'categories': categoriesReducer,
  filters: shopReducer,
  search: searchReducer,
  sliders: sliderReducer,
  featuredProducts: featuredProductReducer,
  subscriber: subscriberReducer,
  snackbar: snackbarReducer,
  userDetails: userReducer,
  visitor: visitorReducer,
  emailVerification: emailVerificationReducer,
  promotions: promotionsReducer,
  // Add other reducers here
});



// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosAuthMiddleware),
  // middleware, devTools, and other store enhancers can be added here
});

// Persistor for the store
export const persistor = persistStore(store);
// export default store;